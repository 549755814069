import React, { useState } from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Externalevents() {
  // const [] = useState()
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dataExternalEvents = [
    {
      date: "24 November 2024",
      des: `Naitik Saxena of Class X-B achieved an impressive third place in the highly esteemed Online
International Value Education Olympiad 2024, organized by the UNEP Faith for Earth Counsellor in
partnership with the Ministry of Environment, showcasing his exceptional commitment to value-
based education and environmental awareness.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/01-12-24-01/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/01-12-24-01/pic2.jpg",
      ],
    },
    {
      des: `Anugya Tiwari of Class XI won 3rd Position in the Drawing Competition of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic1.jpg",
      ],
    },
    {
      des: `Kunal and Manav Vats of Class XII won 3rd Position in the English and Hindi Slogan Writing Competition of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic2.jpg",
      ],
    },
    {
      des: `Ritvik Mishra of Class VIII won 3rd Position in the English Poetry Recitation Competition of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic3.jpg",
      ],
    },
    {
      des: `Shreyas Loshali of Class VIII won 1st Position in the Percussion (Junior Boys) Category of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic4.jpg",
      ],
    },
    {
      des: `Soham Chandola of Class IX won 2nd Position in the Percussion (Senior Boys) Category of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic5.jpg",
      ],
    },
    {
      des: `C. Shakti Vignesh of Class X won 2nd Position in the Solo Classical Dance (Senior Boys and Girls) Category of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic6.jpg",
      ],
    },
    {
      des: `Avni Pandey of Class XII won 3rd Position in the Solo Classical Dance (Senior Boys and Girls) Category of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic7.jpg",
      ],
    },
    {
      des: `The Orchestra Group of Chinmaya Vidyalaya, comprising 12 talented students from Classes IX to XI, won 1st Position in the Orchestra Competition of the Zonal Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic8.jpg",
      ],
    },
    {
      des: `The Orchestra Group of Chinmaya Vidyalaya, comprising 12 talented students from Classes VIII to XI, won 1st Position in the Orchestra Competition of the District Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic9.jpg",
      ],
    },
    {
      des: `Shreyas Loshali of Class VIII won 1st Position in the Percussion (Junior Boys) Category of the District Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic10.jpg",
      ],
    },
    {
      des: `Soham Chandola of Class IX won 2nd Position in the Percussion (Senior Boys) Category of the District Level Cultural Activities organised by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic11.jpg",
      ],
    },
    {
      des: `Manav Vats of Class XII won 3rd Position in the 60 Kg Weight Category at the CBSE Central Zone (Delhi Region) Boxing Championship.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic12.jpg",
      ],
    },
    {
      des: `Adwitiya Dutta of Class VIII secured 5th Position in the CBSE Cluster XX Archery Competition.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic13.jpg",
      ],
    },
    {
      des: `Daksh Jamwal of Class XI won 3rd Position in the CBSE National Shooting Championship.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic14.jpg",
      ],
    },
    {
      des: `Anant Kumar of Class VIII won 3rd Position in the Zonal Yoga Competition (U-14 Boys Category) held by DOE.`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-11-24-01/pic15.jpg",
      ],
    },

    {
      des: `Daksh Jamwal of Class XI won Gold Medal in the CBSE National Shooting Championship held at St. Frobel School, A-3 Paschim Vihar, New Delhi.`,
      date: "22 October 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/19-11-24-01/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/19-11-24-01/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/19-11-24-01/pic3.jpg",
      ],
    },
    {
      des: `Harshita Singh of Class XII secured first position in the event "Cliffhanger" (Leadership Activity) competition, ‘LEAD ON’ organized by Tagore International School, Vasant Vihar.`,
      date: "19th October 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-02/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-02/pic2.jpg",
      ],
    },
    {
      des: `Shivansh (Class XI), Hardik Kumar (Class XII), Satvik Malviya (Class XII), Zayan Khan (Class XII) and Aryan Yadav (Class XII) secured Third Position in the event AI Rena (Online Gaming Competition), Cyfernode 3.0 organised by DLF QEC Innovative School Spring Fields, Gurugram.`,
      date: "16th October 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-02/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-02/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-02/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-02/pic4.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-02/pic5.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-02/pic6.jpg",
      ],
    },
    {
      des: `Vaibhav Nikunj, Atharva Rai and Piyush Prajapati of Class VIII received Certificate of Achievement in the Youth Ideathon 2024, India's Biggest Innovation and Enterpreneurship competition organised by ThinkStartup and MEPSC in association with CBSE.`,
      date: "10th October 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-01/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-01/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-01/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-01/pic4.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-01/pic5.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/28-10-24-01/pic6.jpg",
      ],
    },
    {
      des: `Shivansh Sehrawat and Suryansh of Class VII secured First Position in the Event Art Synth (Generating Images Using AI), Technovanza 10.0 organised by Bal Bharati Public School, Gangaram Hospital Marg on 10.10.2024.`,
      date: "10th October 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-01/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-01/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-01/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-10-24-01/pic4.jpg",
      ],
    },
    {
      des: `Manav Vats of Class XII won Bronze Medal in the CBSE CENTRAL ZONE (Delhi region)
Boxing (56-60 Kg Weight category) Championship held at Mother Divine Public School, Sector-
3, Rohini, Delhi.`,
      date: "13 September 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/19-11-24-02/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/19-11-24-02/pic2.jpg",
      ],
    },
    {
      des: `Prachi Tokas of Class XI secured Gold Medal in the ASBC Asian Junior (Boys &amp; Girls)
International Boxing Championship held at AL AIN City, Abu Dhabi from 27 th August to 10 th
September 2024.`,
      date: "27th August to 10th September 2024.",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/02-10-24-01/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/02-10-24-01/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/02-10-24-01/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/02-10-24-01/pic4.jpg",
      ],
    },
    {
      des: `Tanay Prasad and Shaurya Ish of Class XII got second position in Inter School Science
Exhibition which was organised by Mount Carmel School, Anand Niketan, Delhi on 26 th July,
2024.`,
      date: "26 July, 2024",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-09-24-01/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/26-09-24-01/pic2.jpg",
      ],
    },
    {
      des: `Manuj Arora and Aditya V N of Class X got first position and Daljeet Singh and Deeptalal
Naik of Class XII got third position respectively in Science Quiz competition organised by
Mount Carmel School, Anand Niketan, Delhi on 26th July, 2024.`,
      date: "26 July, 2024",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-09-24-01/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-09-24-01/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/21-09-24-01/pic3.jpg",
      ],
    },
    {
      des: `Setunath Nair of Class VII secured first position in the Hindi (Junior) Inter-School Munshi
Memorial Recitation Competition organised by Bharatiya Vidya Bhavan’s Mehta Vidyalaya on
26th April, 2024.`,
      date: "26 April, 2024",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/external-events/20-09-24-01/pic1.png",
      ],
    },
    {
      des: `Rakshita Sridhar of Class V A and Vishantak Kumar of Class V C secured the
RUNNER-UP position in the Keynote category (PPT making) of ‘Z3NITH 2024’
event organised by Air Force Golden Jubilee Institute, Subroto Park.​`,
      // date: "9 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820242/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820242/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820242/pic3.jpg",
      ],
    },
    {
      des: `Surabhi Sameesh of Class II A bagged the FIRST position in the Matti Srijan
category of ‘Confluence 2024’ online event organised by DAV Public School,
Kailash Hills.​`,
      // date: "9 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820241/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820241/pic2.jpg",
      ],
    },
    {
      des: `Rayhaang Subba and Raghavendra Sagar of Class V C secured a special position in
the Galaxy Geeks category in the event ‘Abhivyakti’ organised by Gyan Mandir
Public School, Naraina Vihar.​`,
      date: "9 May, 2024",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic1.jpeg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic2.jpeg",
      ],
    },
    {
      des: `Augastya Tripathi of Class VII bagged the trophy of Zonal Rank-1, North in IFHO (Hindi Olympiad) conducted by International Olympiad Foundation-2023.​`,
      // date: "Saturday, 2 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic2.jpg",
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic5.jpg",
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic6.jpg",
      ],
    },
    {
      des: `Shreyas Loshali of Class VII bagged the trophy of Zonal Rank-1, North in IFCO (Computer Olympiad) conducted by International Olympiad Foundation-2023.​`,
      // date: "Saturday, 2 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic4.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic5.jpg",
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic6.jpg",
      ],
    },
    {
      des: `Vatsal Bhayani of class-VIII and Adyashree Pradhan of class-V participated in the Shreemad
      Bhagwatgeeta Shloka Uchcharan Abhivyanjana-2023 organized by GYAN BHARTI
      SCHOOL, SAKET, NEW DELHI and got First Position.​`,
      date: "Saturday, 2 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/31-aug-2023-1/Gyan-Bharati-School.jpg",
      ],
    },
    {
      des: `Satvik Malviya, Zayn Khan and Hardik Kumar of class XI participated in the Gaming
      competition BLACKOU1 organized by RAMJAS INTERNATIONAL SCHOOL, RK
      PURAM, NEW DELHI and got Second Position.​`,
      date: "9 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/16-aug-2023-1/pic1.jpeg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/16-aug-2023-1/pic2.jpeg",
      ],
    },
    {
      des: `Bhavya Gulati and Manuj Arora of Class IX secured the 2nd position in the Socio-Tech Masters category in the event "Confluence" organized by Air Force Golden Jubilee Institute, Subroto Park. ​`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/9-aug-2023-1/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/9-aug-2023-1/pic2.jpg",
      ],
    },

    {
      des: `Lasya Priya Yellasiri of Class VI, Shreyas Loshali of Class VII, and Aditya Kumar of Class VIII secured the 1st position in the Mathemagix category in the event 'Abhivyajana' organized by Gyan Bharati School, Saket.`,
      date: "22 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/9-aug-2023-2/pic1.jpg",
      ],
    },
    {
      des: `Mudit Ranjan, Ishant Ratan AND Aditya Rai of Class XII secured 1<sup>st</sup> postion in the Meme
      String Category in the event ‘ORDIN@TRIX23.0’ organized by Tagore International
      School, Vasant Vihar.`,
      date: "4 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-2/Image-2.jpg",
      ],
    },
    {
      des: `Shivansh of Class X AND Tanay Raj of Class IX Secured 1<sup>st</sup> position in the Meme String
      Category in the event "KRITI" organized by DAV Public School, Ashok Vihar.`,
      date: "4 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-2/Image-1.jpg",
      ],
    },
    {
      des: `The Mukhyamantri Vigyan Pratibha Pariksha for the students of class 9 th is meant to
      identify and nurture the talented students. The examination is conducted every year at
      state level. For the session 2022-23 two students ABHIJEET KUMAR (RANK-24,
      MARKS - 79%) and YASHAANSH DUBEY (RANK - 43, MARKS - 69.5%) of Chinmaya
      Vidyalaya made their place in merit list and are eligible for scholarship.`,
      images: [
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-1-march-23.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-1/Abhijeet-Kumar.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-1/Yashaansh-Dubey.jpg",
      ],
    },
  ];

  const school = (
    <div className="externalevents__container">
      {dataExternalEvents.map((item) => (
        <div className="externalevents__card">
          <div>
            <Slider {...settings}>
              {item.images.map((item, i) => (
                <div
                  // style={{ display: "inline-block" }}
                  className="externalevents__card__slider__imgcontainer"
                >
                  <img
                    key={`herodesk-${i}`}
                    alt="Hero Image"
                    loading="eager"
                    src={item}
                  ></img>
                </div>
              ))}
            </Slider>
          </div>
          {item.date && (
            <div className="externalevents__card__date">Date: {item.date}</div>
          )}
          <div
            className="externalevents__card__text"
            dangerouslySetInnerHTML={{ __html: item.des }}
          ></div>
        </div>
      ))}
    </div>
  );

  let uniformJSX = school;

  return (
    <Layout>
      <SEO
        title="Achivements - External Events | Chinmaya Vidyalaya"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="externalevents">
        <h1 className="heading">External Events</h1>

        {uniformJSX}
      </div>
    </Layout>
  );
}
